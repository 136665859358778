<template>
  <base-section
    id="map"
    space="0"
  >
    <iframe
      width="100%"
      height="500"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=No%2020,%20Lorong%20CP8/23,%20Cheras%20Perdana,%20Cheras,%2043200,%20Selangor,%20Malaysia+(NANOTECH%20SOLUTIONS)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    >
    </iframe>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
